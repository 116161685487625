import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"
import Home from "@/views/HomeView.vue"
import FeedbackSummary from "@/views/FeedbackSummaryView.vue"
import NewFeedback from "@/views/NewFeedbackView.vue"
import StrypeHistory from "@/views/StrypeHistoryView.vue"
import StrypeFAQ from "@/views/StrypeFAQView.vue"
import Policy from "@/views/PolicyView.vue"
import Events from "@/views/EventsView.vue"
import PageNotFound from "@/views/PageNotFoundView.vue"

/** Prepare the router **/
const homeRouteName = "home"
export const feedbacksRoutePath = "/feedback"
const feedbacksRouteName = "feedbackSummary"
export const newFeedbackRoutePath = "/newfeedback"
const newFeedbackRouteName = "newFeedback"
export const strypeHistoryRoutePath = "/history"
const strypeHistoryRouteName = "history"
export const strypeFAQRoutePath = "/FAQ"
const strypeFAQRouteName = "FAQ"
export const policyRoutePath = "/policy"
const policyRoutePathName = "policy"
export const eventsRoutePath = "/events"
const eventsRoutePathName = "events"

const routes = [
    { path: "/", component: Home, name: homeRouteName },
    { path: feedbacksRoutePath, component: FeedbackSummary, name: feedbacksRouteName },
    { path: newFeedbackRoutePath, component: NewFeedback, name: newFeedbackRouteName },
    { path: strypeHistoryRoutePath, component: StrypeHistory, name: strypeHistoryRouteName },
    { path: strypeFAQRoutePath, component: StrypeFAQ, name: strypeFAQRouteName },
    { path: policyRoutePath, component: Policy, name: policyRoutePathName },
    { path: eventsRoutePath, component: Events, name: eventsRoutePathName },
    // and finally the default route, when none of the above matches:
    { path: "/:catchAll(.*)", component: PageNotFound },
] as RouteRecordRaw[]
/** End router preparation **/

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: routes,
    scrollBehavior () {
        return { top: 0 } // Scrolls to the top of the page on every route change
    },
})

export default router
